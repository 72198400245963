import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

console.log("NASSFELD SCRIPT"); 

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as toc from './scripts/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as skipLinks from './scripts/skip-links';
app.skipLinks = skipLinks.init;


let selectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
};

import * as fileInput from './scripts/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.createInitInScope(selectors, {
    preventNative: true,
    offset: window.innerHeight / 2
});

import * as tourMap from './scripts/tourMap';
app.modules.tourMap = tourMap.initInScope;

import * as finishRegistrationModal from './scripts/finishRegistrationModal';
app.modules.finishRegistrationModal = finishRegistrationModal.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as isInViewport from './scripts/isInViewport';
app.modules.isInViewport = isInViewport.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as triangleAnimation from './scripts/triangleAnimation';
app.triangleAnimation = triangleAnimation.init;


import * as triangleScrollAnimation from './scripts/triangleScrollAnimation';
app.modules.triangleScrollAnimation = triangleScrollAnimation.initInScope;


import * as parallax from './scripts/parallax';
app.modules.parallax = parallax.initInScope;

import * as cardTeaserSlider from './scripts/cardTeaserSlider';
app.modules.cardTeaserSlider = cardTeaserSlider.initInScope;

import * as dragTeaserSlider from './scripts/dragTeaserSlider';
app.modules.dragTeaserSlider = dragTeaserSlider.initInScope;

import * as expandable from './scripts/expandable';
app.modules.expandable = expandable.initInScope;

import * as ajaxFormScroll from './scripts/ajax-form-scroll';
app.modules.ajaxFormScroll = ajaxFormScroll.initInScope;

import * as slider from '@elements/slider';
app.modules.slider =  slider.createInitInScope('.js-slider', {
    nextArrow: '<button type="button" class="slider-arrow slider-arrow--outlines arrow-next" aria-label="Next" title="Next"><span class="icon icon-arrow-right" aria-hidden="true"></span></button>',
    prevArrow: '<button type="button" class="slider-arrow slider-arrow--outlines arrow-prev" aria-label="Prev" title="Prev"><span class="icon icon-arrow-left" aria-hidden="true"></span></button>'
});

app.modules.brandSlider =  slider.createInitInScope('.js-brand-slider', {
    nextArrow: '<button type="button" class="slider-arrow slider-arrow--outlines arrow-next" aria-label="Next" title="Next"><span class="icon icon-arrow-right" aria-hidden="true"></span></button>',
    prevArrow: '<button type="button" class="slider-arrow slider-arrow--outlines arrow-prev" aria-label="Prev" title="Prev"><span class="icon icon-arrow-left" aria-hidden="true"></span></button>',
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        }
    ]
});

import * as thumbSlider from './scripts/thumbSlider';
app.modules.thumbSlider = thumbSlider.initInScope;

import * as templateItemList from './scripts/templateItemList';
app.modules.templateItemList = templateItemList.initInScope;

import * as productThumbSlider from './scripts/productThumbSlider';
app.modules.productThumbSlider = productThumbSlider.initInScope;

import * as webcamSlider from './scripts/webcamSlider';
app.modules.webcamSlider = webcamSlider.initInScope;

app.modules.badgeTeaserSlider = slider.createInitInScope('.js-badge-teaser-slider', {
    nextArrow: '<button type="button" class="slider-arrow slider-arrow--outlines arrow-next" aria-label="Next" title="Next"><span class="icon icon-arrow-right" aria-hidden="true"></span></button>',
    prevArrow: '<button type="button" class="slider-arrow slider-arrow--outlines arrow-prev" aria-label="Prev" title="Prev"><span class="icon icon-arrow-left" aria-hidden="true"></span></button>',
    arrows: true,
    infinite: false,
    slidesToShow: 2,
    draggable: true,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        }
    ]
});

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as datepicker from './scripts/custom-datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from './scripts/custom-datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as backButton from './scripts/backButton';
app.modules.backButton = backButton.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as dateInput from '@elements/date-input';
app.modules.dateInput = dateInput.createInitInScope({
    placeholderDate: false,
},{});

import * as overlay from './scripts/overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as fadeIn from './scripts/fadeIn';
app.modules.fadeIn = fadeIn.initInScope;

import * as ajaxFormUpload from './scripts/ajax-form-upload';
app.modules.ajaxFormUpload = ajaxFormUpload.initInScope;

import * as tableResponsive from './scripts/tableResponsive';
app.modules.tableResponsive = tableResponsive.initInScope;

import * as portalSwitch from './scripts/portalSwitch';
app.modules.portalSwitch = portalSwitch.initInScope;

import * as dragIndicator from './scripts/dragIndicator';
app.modules.dragIndicator = dragIndicator.initInScope;

import * as darkpage from './scripts/darkpage';
app.modules.darkpage = darkpage.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    addUrlParams: true
});
import * as toggleSubnavDropdown from './scripts/toggle-subnav-dropdown';
app.modules.toggleSubnavDropdown = toggleSubnavDropdown.initInScope;

import * as nav from './scripts/nav';
app.modules.nav = nav.initInScope;

import * as menuAim from './scripts/menuAim';
app.modules.menuAim = menuAim.initInScope;

import * as weatherForecast from './scripts/weatherForecast';
app.modules.weatherForecast = weatherForecast.initInScope;

import * as rangeSlider from './scripts/range-slider';
app.modules.rangeSlider = rangeSlider.initInScope;

import * as navInfoSlider from './scripts/navInfoSlider';
app.modules.navInfoSlider = navInfoSlider.initInScope;

import * as affix from '@elements/affix';
app.modules.affix = affix.initInScope;

import * as affixNavbar from './scripts/affix-navbar';
app.modules.affixNavbar = affixNavbar.initInScope;

import * as touchNav from './scripts/touchNav';
app.modules.touchNav = touchNav.createInitInScope();

import * as hotspots from './scripts/hotspots';
app.modules.hotspots = hotspots.initInScope;

import * as notificationHeader from './scripts/notificationHeader';
app.modules.notificationHeader = notificationHeader.initInScope;

import * as modalInputFocus from './scripts/modal-input-focus';
app.modules.modalInputFocus = modalInputFocus.initInScope;

import * as stepForm from './scripts/stepForm';
app.modules.stepForm = stepForm.initInScope;

import * as imgUpload from './scripts/imgUpload';
app.modules.imgUpload = imgUpload.initInScope;

import * as fancyCheckbox from './scripts/fancyCheckbox';
app.modules.fancyCheckbox = fancyCheckbox.initInScope;

import {translate} from '@elements/translations';
import * as quickFinder from './scripts/quickfinder'; 
app.modules.quickFinder = quickFinder.createInitInScope('.js-quickfinder', {},
    (data) => `<div class="js-quickfinder__childs row">${Object.keys(data).map(function (key) {
        return '<div class="col-4">' +
            '<div class="select form-group"><label class="form-control-label floating-label--small floating-label" for="' + data[key].name + data[key].id + '">' + translate('childAge')+ ' ' + data[key].id + '</label>' +
            '<select name="ca0[]" id="' + data[key].name + data[key].id + '" name="' + data[key].name + data[key].id + '" class="form-control js-floating-label" required>' +
            '<option class="d-none" value="" selected=""></option>' +
            '<option value="0">&lt;1 Jahre</option>' +
            '<option value="1">1 Jahre</option>' +
            '<option value="2">2 Jahre</option>' +
            '<option value="3">3 Jahre</option>' +
            '<option value="4">4 Jahre</option>' +
            '<option value="5">5 Jahre</option>' +
            '<option value="6">6 Jahre</option>' +
            '<option value="7">7 Jahre</option>' +
            '<option value="8">8 Jahre</option>' +
            '<option value="9">9 Jahre</option>' +
            '<option value="10">10 Jahre</option>' +
            '<option value="11">11 Jahre</option>' +
            '<option value="12">12 Jahre</option>' +
            '<option value="13">13 Jahre</option>' +
            '<option value="14">14 Jahre</option>' +
            '<option value="15">15 Jahre</option>' +
            '<option value="16">16 Jahre</option>' +
            '<option value="17">17 Jahre</option>' +
            '</select>' +
            '<div class="form-errors invalid-feedback"></div>' +
            '</div></div>'
    }).join("")}</div>`
);

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init;
smoothScroll.initInScope();

import * as anchorNav from '@elements/anchor-nav';
app.modules.anchorNav  = anchorNav.createInitInScope('.js-anchor-nav', {
    list: '.js-anchor-nav',
    listItem: '.js-anchor-nav__item'
},  {
    renderItem: function (text, anchor) {
        return '<li class="nav-item anchor-nav__item"><a class="btn btn-white js-smoothscroll__link js-render-template__render-all" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
    },
    smoothScroll: true,
    scrollSpy: false,
    scrollSpyOffset: 100
});

import * as consentOverlay from './scripts/cookie-consent-overlay';
app.modules.consentOverlay = consentOverlay.initInScope;

import * as tourConsentOverlay from './scripts/tour-consent-overlay';
app.modules.tourConsentOverlay = tourConsentOverlay.initInScope;

import * as fullHeight from './scripts/fullHeight';
app.fullHeight = fullHeight.init;

import * as countdown from './scripts/countdown';
app.modules.countdown = countdown.initInScope;

import * as iframeResizer from './scripts/iframe-resizer';
app.iframeResizer = iframeResizer.init;

import * as tracking from "@elements/tracking";
let options = {}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as customTracking from './scripts/customTracking';
app.modules.customTracking = customTracking.initInScope;

import * as renderTemplate from './scripts/renderTemplate';
app.modules.renderTemplate = renderTemplate.initInScope;
app.renderAllTemplates = renderTemplate.renderAllTemplates;

import * as lazyIframe from '@elements/lazy-iframe';
app.modules.lazyIframe = lazyIframe.initInScope;

import * as svgConverter from './scripts/svg-converter';
app.modules.svgConverter = svgConverter.initInScope;

import * as regionMap from './scripts/region-map';
app.modules.regionMap = regionMap.initInScope;

import * as reloadForm from "@elements/reload-form";
reloadForm.initInScope($('body'));

import * as buddyStickyBadge from './scripts/buddy-sticky-badge';
app.modules.buddyStickyBadge = buddyStickyBadge.initInScope;

import * as accessiway from './scripts/accessiway';
accessiway.init();

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);
